<template>
  <div class="devBY">
    <van-nav-bar title="设备保养" />

    <van-cell-group>
      <van-cell
        v-for="(item, index) in data1"
        :key="index"
        :title="item.chnname"
        :value="data2[item.fieldname]"
      />
      <van-cell
        v-for="(item, index) in data3"
        :key="index"
        :title="item.parameter_name"
        :value="item.parameter_value"
      />
    </van-cell-group>
    <div class="viewFooter">
      <span v-for="(item, index) in data4" :key="index" @click="action(item)">{{
        item.btn_name
      }}</span>
    </div>
    <div class="viewFooter2">
      <span
        v-for="(item, index) in data6"
        :key="index"
        @click="action2(item)"
        >{{ item.btn_name }}</span
      >
    </div>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="data5"
        value-key="partname"
        :confirm-button-text="$t('module.confirm')"
        :cancel-button-text="$t('module.cancel')"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { postReqFormData, getReq } from '@api/wx.js'
export default {
  data() {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      v8apitoken: '7fa747b7ec0f4007a155e005a79c3701',
      devid: this.$route.query.devid,
      data1: [],
      data2: {},
      data3: [],
      data4: [],
      data5: [],
      data6: [],
      V3url: './',
      showPicker: false
    }
  },
  created() {
    this.ini()
  },
  methods: {
    ini() {
      getReq(
        {
          autoid: 14,
          p01: this.devid
        },
        this.v8apitoken
      ).then(res => {
        this.data1 = res.data1
        this.data2 = res.data2[0]
        this.data3 = res.data3
        this.data4 = res.data4
        this.data6 = res.data6
        this.data5 = res.data5.map(e => {
          return e.upkeep_name
        })
      })
    },
    action(item) {
      // 按钮点击事件
      if (item.btn_name != '保养') {
        var url = 'shengpiinfo.html'
        var title = item.btn_name
        setLocVal(
          'hyrptparas',
          title +
            '|' +
            url +
            '|' +
            item.formautoid +
            '|' +
            JSON.stringify({
              dev_autoid: this.devid
            })
        )
        let href = this.V3url + '/weixinlocal/queryrpt/' + url
        this.$router.push({
          path: '/jump',
          query: { url: href, time: new Date().getTime() }
        })
      } else {
        this.showPicker = true
      }
    },
    action2(item) {
      //佳成-佳成设备维护页
      this.$router.push({
        path: '/EquWH',
        query: {
          moduleno: item.formautoid,
          fromH5: true,
          dev_id_gs: this.data2.dev_id_gs,
          dev_name: this.data2.dev_name,
          devid: this.devid,
          fromPage: 'devBY'
        }
      })
    },
    onConfirm(value) {
      this.showPicker = false
      postReqFormData(
        {
          autoid: 15,
          p01: this.devid,
          p02: value,
          p03: this.userInfo.username
        },
        this.v8apitoken
      ).then(res => {
        let href =
          this.V3url +
          `/weixinlocal/queryrpt/wfoneinfo.html?autoid=${res.data[0].autoid}&type=1`
        this.$router.push({
          path: '/jump',
          query: { url: href, time: new Date().getTime() }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.devBY {
  width: 100%;

  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }

    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }

    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }

  .viewFooter,.viewFooter2 {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 120px;
    width: 100%;
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    span {
      display: inline-block;
      width: 50%;
      height: 100%;
      line-height: 80px;
      box-sizing: border-box;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2b8df0;
      text-align: center;
      border-left: 1px solid #ccc;
      flex: 1;
    }
    span:first-child {
      flex: 1;
      border-left: 0px solid #ccc;
    }
  }
  .viewFooter2{
    bottom: 0;
  }
}
</style>
